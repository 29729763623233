body {
  overflow: hidden;
  overscroll-behavior: none; /* prevent drag refresh */
  background: linear-gradient(180deg, rgb(98, 0, 181, 1) 0%, rgba(74, 8, 103, 1) 100%);
}

body * {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  color: white;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1.25rem;
}

input {
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 4px;
  color: #282828;
}

button, a {
  border-radius: 4px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #282828;
  padding: 0.5rem 1rem;
}