.Page__prompts {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.Page__prompts-list, .Page__prompts-editor {
  height: 100%;
}

.Page__prompts-list {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  flex-direction: column;
  width: calc(40% - 0.5rem);
  height: 100%;
  border-right: 1px solid white;
  margin-right: 0.5rem;
  padding-right: 0.5rem;

  h2 {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.Page__prompts-list-container {
  width: 100%;
  flex: 1;
}

.Page__prompts-list-prompt {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  width: 100%;
  font-size: 1.5rem;
  background-color: white;
  color: #282828;
  padding: 0.5rem;
  border-radius: 4px;
  height: 43.5px;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }

  &:hover, &.open {
    background-color: #505050;
    color: white;
    cursor: pointer;
  }
}

.Page__prompts-list-add {
  margin-top: 0.5rem;
}

.Page__prompts-editor {
  display: flex;
  flex-direction: column;
  width: 60%;

  input {
    font-size: 1.5rem;
    border: 0;
  }
}

.Page__prompts-editor-name,
.Page__prompts-editor-body,
.Page__prompts-editor-buttons {
  width: 100%;
  flex: 0;
}

.Page__prompts-editor-buttons-prompt-usage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  input[type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
}

.Page__prompts-editor-active {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
  text-align: right;
}

.Page__prompts-editor-name {
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.Page__prompts-editor-body {
  flex: 1;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: #282828;
  padding: 0.5rem;
  border-radius: 4px;
}

.Page__prompts-editor-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 0.5rem;

  button {
    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }

    &.Page__prompts-editor-buttons-delete {
      &:hover {
        background-color: firebrick;
        color: white;
      }
    }
  }
}

.Page__prompts-info {
  font-size: 2rem;
}

.Page__prompt-form {
  width: 80%;
  height: 80%;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  color: #282828;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
