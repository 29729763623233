.App__PatientEncounters {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
}

.App__PatientEncounters-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex: 0;
  background: linear-gradient(180deg, rgb(98, 0, 181, 1) 0%, rgba(74, 8, 103, 1) 100%);
  padding: 1rem;

  h1 {
    flex: 1;
    text-align: right;
    font-size: 2rem;

    @media screen and (max-width: 600px) {
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem;
  }
}

.App__PatientEncounters-header-logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 0;

  img {
    width: 50px;
    height: auto;
    padding: 0.5rem;
    background-color: white;
    border-radius: 8px;

    @media screen and (max-width: 600px) {
      width: 40px;
    }
  }

  h2 {
    margin-left: 0.5rem;
    font-size: 1.5rem;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.App__PatientEncounters-header-title-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.App__PatientEncounters-header-status {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: orange;
  margin-left: 0.5rem;

  &.connected {
    background-color: green;
  }
}

.App__PatientEncounters-body {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: auto hidden;

  * {
    color: #282828;
  }
}

.App__PatientEncounters-table-body {
  display: block;
  overflow: auto scroll;
  height: calc(100vh - 114px);
  min-width: 1200px;

  @media screen and (min-width: 1200px) {
    min-width: 100%;
  }
}

.App__PatientEncounters-table-header-delete {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 30px;
  height: 30px;

  button {
    background-color: transparent;
    text-align: left;
    border: 0;
    width: 100%;
    font-size: 1rem;

    img {
      width: auto;
      height: 12px;
    }
  }
}

.App__PatientEncounters-table-header-delete-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 30px !important;
}

.App__PatientEncounters-table-header-delete-options {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  border: 1px solid #aaa;

  button {
    white-space: nowrap;
    border: 0;
    border-radius: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid #aaa;
    }

    &:hover {
      background-color: #aaa;
    }
  }
}

.App__PatientEncounters-table-body-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App__PatientEncounters-table-body-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  min-width: 30px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0.5rem;
    border: 0;

    img {
      height: 20px;
      width: auto;
    }
  }
}

.App__PatientEncounters-body-waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
}

.App__PatientEncounters-table {
  width: 100%;
}

.App__PatientEncounters-table-header,
.App__PatientEncounters-table-row {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #aaa;
  height: 30px;
}

.App__PatientEncounters-table-header {
  min-width: calc(1200px - 17px);

  @media screen and (min-width: 1200px) {
    min-width: calc(100% - 17px);
  }

  &.mac {
    min-width: 1200px;

    @media screen and (min-width: 1200px) {
      min-width: 100%;
    }
  }

  &.body-overflow {
    width: calc(100% - 17px); // scrollbar
  }
}

.App__PatientEncounters-table-header {
  &.body-overflow {
    @media screen and (max-width: 1200px) {
      width: calc(1200px - 17px); // scrollbar
    }
  }
}

.App__PatientEncounters-table-header-cell,
.App__PatientEncounters-table-body-cell.center {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.App__PatientEncounters-table-header-cell,
.App__PatientEncounters-table-body-cell {
  width: 16%;
  overflow: hidden;
  padding: 0.25rem;
  
  &:not(:first-of-type) {
    border-left: 1px solid #aaa;
  }

  &.created,
  &.last-modified-time {
    width: 10%;
  }

  &.transcript {
    width: 16%;
  }

  &.chart {
    width: 20%;
  }

  &.final-chart {
    width: 12%;
  }

  @media screen and (max-width: 1200px) {
    width: 26%;

    &.created,
    &.last-modified-time,
    &.transcript {
      width: 6%;
    }

    &.chart {
      width: 18%;
    }

    &.final-chart {
      width: 12%;
    }
  }
}

.App__PatientEncounters-table-body-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  &:hover {
    background-color: #dcdcdc;
    cursor: pointer;
  }
}

.App__PatientEncounters-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);

  h3 {
    width: 100%;
    text-align: left;
    padding: 1rem 5%;
  }
}

.App__PatientEncounters-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 4px;
  color: #282828;

  textarea {
    width: 90%;
    height: 75%;
    padding: 0.5rem;
    font-size: 1.25rem;
  }
}

.App__PatientEncounters-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  border: 0;
  z-index: 1;

  img {
    width: 24px;
    height: auto;
  }
}

.App__PatientEncounters-modal-save {
  margin-top: 1rem;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}