.Page__Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 2.75rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  input {
    border: 0;

    &:last-of-type {
      margin-top: 0.5rem;
    }
  }

  button {
    border: 0;
  }
}

.Page__Login-btn {
  margin-top: 1rem;
}

.Page__Login-logo {
  width: 200px;
  height: auto;
}