.App__Home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 0.5rem;
  }

  a, button {
    background-color: #fff;
    text-decoration: none;
    min-width: 30%;
    text-align: center;
    padding: 1rem;

    &:not(:first-of-type) {
      margin-top: 0.5rem;
    }
    
    @media screen and (max-width: 500px) {
      min-width: 70%;
    }
  }

  button {
    margin-top: 0.5rem;
    border: 0;
  }
}
