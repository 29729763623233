.App__NewEncounter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  h1 {
    margin-bottom: 0.5rem;
  }

  button {
    background-color: #fff;
    text-decoration: none;
    min-width: 30%;
    text-align: center;
    padding: 1rem;

    &:last-of-type {
      margin-top: 0.5rem;
    }
    
    @media screen and (max-width: 500px) {
      min-width: 70%;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
}

.App__NewEncounter-speak-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

@keyframes pulse {
  0% {
      transform: scale(1.0);
  }
  100% {
      transform: scale(1.1);
  }
}

.App__NewEncounter-speak-icon {
  height: 150px;
  animation: pulse 1.5s ease-in-out infinite alternate;
  margin: 1rem 0;
}

.App__NewEncounter-transcribed-text {
  flex: 1;
  border: 1px solid white;
  width: calc(100% - 2rem);
  padding: 0.5rem;
  font-size: 1.25rem;
  margin-top: 0.5rem;
  overflow-y: auto;
  border-radius: 4px;
  color: #282828;
}